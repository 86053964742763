import Image from "next/future/image"; // Using future/image to get rid of span elements wrapping the images
import { Grid } from "@/components/shared/Grid";
import { parseText } from "@/utilities/htmlParser";
import { GetDynamicContentItemProps } from "@/components/blocks/DynamicContentBlock/types";
import PageHeaderTwoLinks from "./PageHeaderTwo/PageHeaderTwoLinks";
import PageHeaderTwoCircle from "./PageHeaderTwo/PageHeaderTwoCircle";

export type PageHeaderTwoProps = GetDynamicContentItemProps<"PageHeaderTwo">;

const PageHeaderTwo: React.FC<PageHeaderTwoProps> = ({
  title,
  subtitle,
  body,
  image: img,
  button_text,
  button_target,
  textlink_text,
  textlink_target,
  circle_enabled,
  className,
}) => {
  const image = img?.[0];
  return (
    <header data-block="PageHeaderTwo" className={`bg-grey-light  ${className ?? ""}`}>
      <Grid className="relative z-0 h-full">
        <div className="tablet:mt-[82px] tablet:mb-32 tablet:min-h-[480px] relative col-span-6 my-12">
          {title && (
            <h1 className="text-p text-secondary-burgundy tablet:mb-10 tablet:text-h3-sm mb-2 font-serif tracking-wider">
              {title}
            </h1>
          )}
          {subtitle && (
            <h2 className="text-h1-sm tablet:mb-10 tablet:text-h1-md font-sans font-bold tracking-wider">
              {subtitle}
            </h2>
          )}
          {body && (
            <div className="text-p tablet:mb-10 font-serif tracking-wider">{parseText(body)}</div>
          )}
          {/* tagline is (likely) not used anymore, commented out until decided it can be fully removed */}
          {/* {tagline && (
            <h4 className="mt-6 font-sans text-h5-sm font-bold tracking-wider tablet:mt-12 tablet:text-h4-sm">
              {parseUnderline(tagline)}
            </h4>
          )} */}
          <PageHeaderTwoLinks
            buttonText={button_text}
            buttonTarget={button_target?.[0]}
            textlinkText={textlink_text}
            textlinkTarget={textlink_target?.[0]}
          />
        </div>

        {image?.desktop && (
          <div className="tablet:block relative -z-10 col-span-5 col-start-8 hidden">
            {circle_enabled && <PageHeaderTwoCircle />}
            <div className="absolute aspect-square h-full">
              <Image
                src={image.desktop}
                alt={image.description || "header image"}
                priority
                width={650}
                height={690}
                className="max-w-none h-full object-cover"
              />
            </div>
          </div>
        )}
      </Grid>
      {image?.mobile && (
        <div className="tablet:hidden relative block aspect-[360/220] min-h-56">
          <Image
            src={image.mobile}
            alt={image.description || "header image"}
            className="object-cover w-full"
            priority
            width={360}
            height={220}
          />
        </div>
      )}
    </header>
  );
};

export default PageHeaderTwo;
