import React from "react";
import ArrowRight from "../Icons/ArrowRight";

type Color = "primary" | "secondary";

interface CardIconButtonProps {
  className?: string;
  isHovered?: boolean;
  color?: {
    default?: Color;
    hover?: Color;
  };
  whiteArrow?: boolean;
  preventLink?: boolean;
}

const CardIconButton: React.FC<CardIconButtonProps> = (props) => {
  const { isHovered, color, className } = props;

  const defaultColor = color?.default ?? "primary";
  const hoverColor = color?.hover ?? "secondary";

  return (
    <div
      className={`transition-1 hover:border-gradient-base max-w-fit cursor-pointer rounded-full
        p-2 hover:pl-3 hover:pr-1 hover:before:rounded-full hover:before:bg-gradient-to-r hover:before:p-[2px]
        ${isHovered ? "pl-3 pr-1" : ""}
        ${styles.button[isHovered ? hoverColor : defaultColor]} ${className ?? ""}`}
      data-gtm-id="link_click"
    >
      <ArrowRight className="h-8 w-8" fill={props.whiteArrow ? "white" : undefined} />
    </div>
  );
};

const styles: {
  button: Record<Color, string | undefined>;
} = {
  button: {
    primary:
      "border-gradient-base from-secondary-burgundy before:rounded-full before:bg-gradient-to-r before:p-[2px]",
    secondary:
      "border-gradient-base from-primary-mint before:rounded-full before:bg-gradient-to-r before:p-[2px]",
  },
};

export default CardIconButton;
