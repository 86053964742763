// For more info about the fallback image see: https://stackoverflow.com/questions/66949606/what-is-the-best-way-to-have-a-fallback-image-in-nextjs

import React, { useEffect, useState } from "react";
import Image, { ImageProps as NextImageProps } from "next/image";

export interface ImageProps extends NextImageProps {
  src: NextImageProps["src"];
  fallbackSrc?: NextImageProps["src"];
}

const ImageWithFallback: React.FC<ImageProps> = (props) => {
  const { src, fallbackSrc = "/placeholder.svg", className, objectFit, ...nextImageProps } = props;
  const [imgSrc, setImgSrc] = useState(src);

  const showPlaceholder = src !== imgSrc;

  const onError = () => {
    if (fallbackSrc) setImgSrc(fallbackSrc);
  };

  useEffect(() => {
    if (!src) return;
    setImgSrc(src);
  }, [src]);

  return (
    <Image
      {...nextImageProps}
      alt={nextImageProps.alt || ""}
      src={imgSrc ?? "/none"}
      onError={onError}
      objectFit={showPlaceholder ? "none" : objectFit}
      className={`${showPlaceholder ? "bg-white" : "bg-none"} ${className ?? ""}`}
    />
  );
};

export default ImageWithFallback;
